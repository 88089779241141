const CartClose = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8525 5.31002C16.4558 4.91325 15.8125 4.91325 15.4157 5.31002L11.1268 9.59898L6.83815 5.31037C6.44138 4.9136 5.79808 4.9136 5.40131 5.31037C5.00454 5.70714 5.00455 6.35043 5.40131 6.7472L9.68993 11.0358L5.40105 15.3247C5.00428 15.7215 5.00428 16.3648 5.40105 16.7615C5.79782 17.1583 6.44111 17.1583 6.83788 16.7615L11.1268 12.4726L15.416 16.7619C15.8128 17.1586 16.456 17.1586 16.8528 16.7619C17.2496 16.3651 17.2496 15.7218 16.8528 15.325L12.5636 11.0358L16.8525 6.74685C17.2493 6.35009 17.2493 5.70679 16.8525 5.31002ZM16.921 6.02844C16.921 6.22979 16.8441 6.43115 16.6905 6.58477L14.465 8.81028L12.2395 11.0358L12.2395 11.0358L16.6905 6.58481C16.8441 6.43117 16.921 6.22981 16.921 6.02844ZM5.34966 6.19075C5.37992 6.33528 5.45115 6.47295 5.56336 6.58516L10.014 11.0358L10.014 11.0358L5.56336 6.58512C5.45116 6.47292 5.37992 6.33527 5.34966 6.19075ZM5.34682 16.1922C5.37547 16.3414 5.44757 16.4839 5.56309 16.5994C5.87037 16.9067 6.36856 16.9067 6.67583 16.5994L11.1268 12.1485L15.578 16.5998C15.8853 16.9071 16.3835 16.9071 16.6908 16.5998C16.8462 16.4444 16.923 16.2401 16.9212 16.0364C16.923 16.2401 16.8462 16.4444 16.6908 16.5998C16.3835 16.9071 15.8853 16.9071 15.578 16.5998L11.1268 12.1486L6.67583 16.5995C6.36856 16.9068 5.87037 16.9068 5.56309 16.5995C5.44756 16.4839 5.37547 16.3414 5.34682 16.1922Z"
        fill="#333333"
      />
    </svg>
  )
}

export default CartClose

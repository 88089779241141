const Download = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7015 19.9797C19.1572 19.8864 19.5 19.4832 19.5 19L19.5 14.75C19.5 14.1977 19.0523 13.75 18.5 13.75C17.9477 13.75 17.5 14.1977 17.5 14.75L17.5 18L4.625 18L4.625 14.75C4.625 14.1977 4.17728 13.75 3.625 13.75C3.07272 13.75 2.625 14.1977 2.625 14.75L2.625 19C2.625 19.5523 3.07272 20 3.625 20L18.5 20C18.569 20 18.6364 19.993 18.7015 19.9797ZM12.0625 3.0625C12.0625 2.51021 11.6148 2.0625 11.0625 2.0625C10.5102 2.0625 10.0625 2.51021 10.0625 3.0625L10.0625 12.3358L7.51961 9.79289C7.12908 9.40237 6.49592 9.40237 6.10539 9.79289C5.71487 10.1834 5.71487 10.8166 6.10539 11.2071L10.3548 15.4565C10.355 15.4567 10.3552 15.4569 10.3554 15.4571C10.4042 15.5059 10.4568 15.5486 10.5123 15.5852C10.9004 15.8415 11.4279 15.7988 11.7696 15.4571L16.0196 11.2071C16.4101 10.8166 16.4101 10.1834 16.0196 9.79289C15.6291 9.40237 14.9959 9.40237 14.6054 9.79289L12.0625 12.3358L12.0625 3.0625Z"
        fill="#333333"
      />
    </svg>
  )
}

export default Download

import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { Cart as CartType } from 'lib/shopify/types'
import { normalizeCart } from 'lib/utils/normalize'
import { syncCart } from 'lib/shopify/api/cart'
import fetchGraphqlApi from 'lib/config/fetch-graphql-api'
import { removeFromCartMutation } from 'lib/shopify/mutations/cart'
import { SHOPIFY_CHECKOUT_ID_COOKIE } from 'lib/config/const'
import { updateCodeFn } from './use-code-update'

// todo!
import { ValidationError } from '@commerce/utils/errors'

import { useCart } from './use-cart'

export async function removeItemFn(
  locale: string = 'us',
  lineIds: string[],
  id?: string
) {
  let lineItemIds = lineIds
  if (!Array.isArray(lineIds)) {
    lineItemIds = [lineIds]
  }

  const checkoutIdCookie = SHOPIFY_CHECKOUT_ID_COOKIE[locale]
  const cartId = id || Cookies.get(checkoutIdCookie)
  if (!cartId) {
    throw new ValidationError({
      message: 'Invalid input used for this operation: Miss cartId',
    })
  }
  const { res } =
    await fetchGraphqlApi<CartType.ShopifyRemoveFromCartOperation>({
      locale,
      query: removeFromCartMutation,
      variables: {
        cartId,
        lineIds: lineItemIds,
      },
      cache: 'no-store',
    })

  if (res?.cartLinesRemove?.cart) syncCart(res.cartLinesRemove.cart, locale)
  if (
    res?.cartLinesRemove?.cart?.discountCodes?.some((item) => !item.applicable)
  ) {
    const code =
      res?.cartLinesRemove?.cart?.discountCodes
        ?.filter((item) => !!item.applicable)
        ?.map((item) => item.code) || []
    return await updateCodeFn(locale, code, cartId)
  }
  return normalizeCart(res.cartLinesRemove.cart)
}

export function useRemoveItem(id?: string) {
  const { locale } = useRouter()

  const { mutate } = useCart()
  const removeItem = useCallback(
    async (input: { id: string[] }) => {
      const lineIds = input?.id

      if (!lineIds) {
        throw new ValidationError({
          message: 'Invalid input used for this operation: Miss lineIds',
        })
      }

      const data = await removeItemFn(locale, lineIds, id)
      await mutate(data, false)
      return data
    },
    [locale, id, mutate]
  )

  return removeItem
}

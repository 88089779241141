import React, { FC } from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Cross, ChevronLeft, Download, CartClose } from '@components/icons/next'
import { Text } from '@components/ui'
import cn from 'classnames'
import dynamic from 'next/dynamic'
import s from './SidebarLayout.module.css'

const SaveEmailDialog = dynamic(() =>
  import('@components/common/SaveEmailDialog')
)

const SidebarLayout = ({
  children,
  className,
  handleClose,
  handleBack,
  headerTitle,
  metafields,
}) => {
  const [showDialog, setShowDialog] = React.useState(false)
  return (
    <div className={cn(s.root, className)}>
      <header className={s.header}>
        {headerTitle && (
          <div className="flex-1">
            <Text html={headerTitle}></Text>
          </div>
        )}
        {showDialog && <SaveEmailDialog onClose={() => setShowDialog(false)} />}

        <div className={s.downloadBox}>
          <Download onClick={() => setShowDialog(true)} />
          <span className={s.downloadTooltip}>
            Save to Email
            {/* <span></span> */}
          </span>
        </div>
        {handleClose && (
          <button
            onClick={handleClose}
            aria-label="Close"
            tabIndex="0"
            className="flex items-center transition duration-150 ease-in-out hover:text-accent-5"
          >
            <CartClose className="h-5 w-5" />
            {/* <XMarkIcon className="h-5 w-5" /> */}
          </button>
        )}
        {handleBack && (
          <button onClick={handleBack} aria-label="Go back" className=" ">
            <ChevronLeft className="h-6 w-6 hover:text-accent-3" />
            <span className="ml-2 text-xs text-accent-7">Back</span>
          </button>
        )}
      </header>
      <div className={s.container}>{children}</div>
    </div>
  )
}

export default SidebarLayout

import cn from 'classnames'
import { useRouter } from 'next/router'
import { Text } from '@components/ui'
import s from './ProductPrice.module.css'

const ProductPrice = ({
  free,
  price = '',
  className = '',
  basePrice = '',
  availableForSale,
  settings,
  styles,
  comingSoon = '',
  variantInfos = {},
  onClick,
  discountPrice,
  waitRouter,
  autoTestTag,
  soldOutAutoTestTag,
}) => {
  const router = useRouter()
  const autoTestTagStyle = autoTestTag ? { tag: autoTestTag } : {}
  const soldOutAutoTestTagStyle = soldOutAutoTestTag
    ? { tag: soldOutAutoTestTag }
    : {}
  return waitRouter && !router.isReady ? (
    <div
      onClick={() => onClick && onClick()}
      className={cn(className, s.prices)}
      style={{ opacity: 0 }}
    >
      <span>Price Loading</span>
    </div>
  ) : (
    <>
      <div
        onClick={() => onClick && onClick()}
        className={cn(className, s.prices)}
      >
        {availableForSale ? (
          <>
            <span
              {...autoTestTagStyle}
              className={cn(s.price, styles?.price, basePrice && 'mr-1', {
                [styles?.codePrice]: basePrice,
              })}
            >
              {free ? settings?.free : price}
            </span>
            {basePrice ? (
              <span className={cn(s.basePrice, styles?.basePrice)}>
                {basePrice}
              </span>
            ) : null}
            {discountPrice ? (
              <Text
                className={cn(s.discountPrice, styles?.discountPrice)}
                html={discountPrice}
                variant="inline"
              />
            ) : null}
          </>
        ) : (
          <span
            {...soldOutAutoTestTagStyle}
            className={cn(s.soldOut, styles?.soldOut)}
            dangerouslySetInnerHTML={{
              __html:
                comingSoon || variantInfos?.comingSoon || settings?.soldOut,
            }}
          ></span>
        )}
      </div>
    </>
  )
}

export default ProductPrice

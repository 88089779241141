const Delete = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.36668 1.74995C7.36668 1.30812 7.72485 0.949951 8.16668 0.949951H11.8333C12.2752 0.949951 12.6333 1.30812 12.6333 1.74995C12.6333 2.19178 12.2752 2.54995 11.8333 2.54995H8.16668C7.72485 2.54995 7.36668 2.19178 7.36668 1.74995ZM8.16668 7.36662C8.60851 7.36662 8.96668 7.72479 8.96668 8.16662V13.6666C8.96668 14.1084 8.60851 14.4666 8.16668 14.4666C7.72485 14.4666 7.36668 14.1084 7.36668 13.6666V8.16662C7.36668 7.72479 7.72485 7.36662 8.16668 7.36662ZM12.6333 8.16662C12.6333 7.72479 12.2752 7.36662 11.8333 7.36662C11.3915 7.36662 11.0333 7.72479 11.0333 8.16662V13.6666C11.0333 14.1084 11.3915 14.4666 11.8333 14.4666C12.2752 14.4666 12.6333 14.1084 12.6333 13.6666V8.16662ZM0.950012 4.49995C0.950012 4.05812 1.30818 3.69995 1.75001 3.69995H3.70001H4.50001H15.5H16.3L18.25 3.69995C18.6918 3.69995 19.05 4.05813 19.05 4.49995C19.05 4.94178 18.6918 5.29995 18.25 5.29995H16.3V17.25C16.3 18.2441 15.4941 19.05 14.5 19.05H5.50001C4.5059 19.05 3.70001 18.2441 3.70001 17.25V5.29995H1.75001C1.30818 5.29995 0.950012 4.94178 0.950012 4.49995ZM5.30001 5.29995V17.25C5.30001 17.3604 5.38955 17.45 5.50001 17.45H14.5C14.6105 17.45 14.7 17.3604 14.7 17.25V5.29995H5.30001Z"
        fill="#999999"
      />
    </svg>
  )
}
export default Delete

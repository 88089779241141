const Code = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.3275 13.404L13.4123 19.3192C13.1028 19.629 12.6828 19.8031 12.2449 19.8031C11.8069 19.8031 11.387 19.629 11.0775 19.3192L3.66667 11.9166V3.66663H11.9167L19.3275 11.0775C19.9672 11.721 19.9672 12.7604 19.3275 13.404Z"
        stroke="#777777"
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
      <circle cx="9.5" cy="9.5" r="1.5" stroke="#777777" strokeWidth="1.6" />
    </svg>
  )
}
export default Code

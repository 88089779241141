import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { Cart as CartType } from 'lib/shopify/types'
import { normalizeCart } from 'lib/utils/normalize'
import fetchGraphqlApi from 'lib/config/fetch-graphql-api'
import { discountCodesUpdateCartMutation } from 'lib/shopify/mutations/cart'
import { SHOPIFY_CHECKOUT_ID_COOKIE } from 'lib/config/const'

// todo!
import { ValidationError } from '@commerce/utils/errors'

import { useCart } from './use-cart'

export async function updateCodeFn(
  locale: string = 'us',
  discountCodes: string[],
  id?: string
) {
  const checkoutIdCookie = SHOPIFY_CHECKOUT_ID_COOKIE[locale]
  const cartId = id || Cookies.get(checkoutIdCookie)

  if (!cartId) {
    throw new ValidationError({
      message: 'Invalid input used for this operation: Miss cartId',
    })
  }
  const { res } =
    await fetchGraphqlApi<CartType.ShopifyCodesUpdateCartOperation>({
      locale,
      query: discountCodesUpdateCartMutation,
      variables: {
        cartId,
        discountCodes,
      },
      cache: 'no-store',
    })
  return normalizeCart(res.cartDiscountCodesUpdate.cart)
}

export function useCodeApply(id?: string) {
  const { locale } = useRouter()

  const { mutate, data } = useCart()
  const codeApply = useCallback(
    async (input: { discountCode: string | string[]; only?: boolean }) => {
      const code = input?.discountCode
      const onlyCode = input?.only

      if (!code) {
        throw new ValidationError({
          message: 'Invalid input used for this operation: Miss discountCode',
        })
      }
      let discountCodes: string[] = []
      if (!onlyCode) {
        discountCodes = (data?.discountCodes || [])
          .filter((item: CartType.ExportDiscounts) => item.applicable)
          .map((item: CartType.ExportDiscounts) => item.code)
      }
      if (Array.isArray(code)) {
        discountCodes.push(...code)
      } else if (typeof code === 'string') {
        discountCodes.push(code)
      }
      const result = await updateCodeFn(locale, [...new Set(discountCodes)], id)
      const applyCodeStutas = result.discountCodes.find((item) => {
        if (Array.isArray(code)) {
          return (
            item.applicable &&
            code.some((codeString) => item.code === codeString)
          )
        } else if (typeof code === 'string') {
          return item.applicable && item.code === code
        }
      })

      let customCartInfo = null
      try {
        const storedInfo = localStorage
          ? localStorage?.getItem('customCartInfo')
          : ''
        if (storedInfo) {
          customCartInfo = JSON.parse(storedInfo || '')
        }
      } catch (e) {
        // console.error("Parsing error:", e)
      }
      const customProducts = result?.lineItems?.filter((item) =>
        item?.product?.tags?.find((tag) => tag === customCartInfo?.product_tag)
      )
      let customPrice =
        customProducts?.reduce(
          (acc, item) => acc + Number(item?.subtotalAmount || 0),
          0
        ) || 0
      let customCodeApply = false
      if (customCartInfo?.code_prefix && result?.discountCodes) {
        customCodeApply = result.discountCodes.some((item) =>
          item?.code?.includes(customCartInfo.code_prefix)
        )
      }
      if (!applyCodeStutas) {
        if (customPrice >= customCartInfo?.min_amount && customCodeApply) {
          throw new ValidationError({
            message: customCartInfo?.code_error_custom || '',
          })
        } else {
          throw new ValidationError({
            message:
              customCartInfo?.code_error ||
              'The items in your cart are not eligible for the coupon code you entered. Review the coupon terms and your cart contents.',
          })
        }
      }
      await mutate(result, false)
      return result
    },
    [data, locale, id, mutate]
  )

  return codeApply
}

export function useRemoveCode(id?: string) {
  const { locale } = useRouter()

  const { mutate, data } = useCart()
  const removeCode = useCallback(
    async (input: { discountCode: string }) => {
      const code = input?.discountCode
      let discountCodes = []
      if (!data) {
        throw new ValidationError({
          message: 'Invalid input used for this operation: Miss Cart Data',
        })
      }

      if (code) {
        discountCodes = data.discountCodes
          .filter(
            (item: CartType.ExportDiscounts) =>
              item.applicable && item.code !== code
          )
          .map((item: CartType.ExportDiscounts) => item.code)
      }

      const result = await updateCodeFn(locale, discountCodes, id)
      await mutate(result, false)
      return result
    },
    [data, locale, id, mutate]
  )

  return removeCode
}
